.migrations-analytics-overlay-container{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);

    display: flex;
    justify-content: flex-end;
}

.migrations-analytics-overlay-content{
    width: 100%;
    max-width: 800px;
    background-color: #000;
    padding: 16px;
    box-sizing: border-box;
}

.migrations-analytics-overlay-content h1{
    margin: 0;
}

.migration-weekly-content{
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.migration-date-date{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
}

.row-migration{

    display: grid;
    grid-template-columns: 3fr 2fr 1fr 2fr;
    gap: 8px;
    border-bottom: 1px solid rgba(255,255,255,0.24);
    background-color: #222;
    /* border-radius: 1rem; */
    box-sizing: border-box;
    padding: 4px 16px;
    box-sizing: border-box;
    align-items: center;
    /* max-height: 32px; */
    gap: 8px;
    align-items: center;
    margin-top: 4px;
    justify-self: center;
    align-self: center;
    max-width: 1100px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 4px;
}

.row-migration:hover{
    background-color: #666;
}

.migration-column{
    font-size: 14px;
    align-items: center;
    display: flex;
    /* padding-top: 8px; */
}